/* You can add global styles to this file, and also import other style files */
@import '~ngx-org-chart/_theming.scss';

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/roboto.ttf') format('truetype')
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  padding: 0;
  margin: 0;
  ;
}


* {
  margin: 0;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box !important;
}

p {
  color: #000;
}


.my-12 {
  margin: 12px 0px;
}
.mt-12{
  margin-top: 12px;
}

.mb-34 {
  margin-bottom: 34px;
}

.me-10 {
  margin-right: 10px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-15 {
  font-size: 15px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-25 {
  font-size: 25px;
}

.fs-30 {
  font-size: 30px !important;
}

.p-10 {
  padding: 10px;
}

.p-12 {
  padding: 12px;
}

.p-25 {
  padding: 25px;
}

.fw-500 {
  font-weight: 500;
}

.rounded-10 {
  border-radius: 10px;
}

.bg-light1 {
  background-color: #f4f7f9;
}

.bg-info1 {
  background-color: #62bcc3;
}

.btn-info1 {
  background-color: #62bcc3;
  border-color: #62bcc3;
  text-transform: uppercase;
}

.btn-dark1:hover,
.btn-dark1:active,
.btn-dark1:focus {
  background-color: #009688;
  color: #ffffff;
  border-color: #009688;
}

.btn-dark1 {
  background-color: #5c5c5c;
  color: #c3c3c3;
}

.btn-info2 {
  background-color: #4d999f;
  border-color: #4d999f;
  text-transform: uppercase;
}

.btn-info2:hover {
  background-color: #1e494d;
  border-color: #1e494d;
}

.bg-danger2 {
  background-color: #ff0000;
}

.bg-warning2 {
  background-color: #e8aa42;
}

.bg-danger1,
.bg-warning1 {
  background-color: #ededed;
}

.tab-table tr:hover .bg-danger1,
.bg-danger1:hover,
.profile-details tr:hover .bg-danger1 {
  background-color: #d90606;
  color: #fff;
}

.tab-table tr:hover .bg-danger1 img,
.bg-danger1:hover img,
.profile-details tr:hover .bg-danger1 img {
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(50deg) brightness(172%) contrast(292%);
  transition-duration: 0.2s;
}

.bg-success1 {
  background-color: #00860b;
}

.text-info1 {
  color: #62bcc3;
}

.text-info2 {
  color: #009688;
}

.text-danger1 {
  color: #d90606;
}

.btn-secondary1,
.btn-warning1 {
  background-color: #ededed;
}

.btn-warning1:hover,
tr.disable-tr .btn-warning1,
.profile-details tr:hover .btn-warning1 {
  background-color: #ef6c2b;
  color: #fff;
}

tr.disable-tr .mngr {
  opacity: 0.4;
}

tr.disable-tr,
tr.disable-tr:hover td {
  color: #c3c3c3 !important;
}

tr.disable-tr:hover .btn-secondary1,
tr.disable-tr .btn-secondary1 {
  background-color: transparent !important;
  color: #c3c3c3 !important;
  cursor: default;
}

tr.disable-tr:hover .dropdown .dropdown-menu {
  display: none;
}

.tab-table tr:hover .btn-secondary1 img,
.btn-secondary1:hover img.reset,
.btn-warning1:hover img,
tr.disable-tr .btn-warning1 img,
.profile-details tr:hover .btn-secondary1 img.reset,
.profile-details tr:hover .btn-secondary1 img.mngr,
.profile-details tr:hover .btn-warning1 img {
  filter: invert(126%) sepia(0%) saturate(0%) hue-rotate(82deg) brightness(332%) contrast(195%);
  transition-duration: 0.3s;

}

.tab-table tr:hover .btn-secondary1,
.btn-secondary1:hover,
.profile-details tr:hover .btn-secondary1 {
  background-color: #62bcc3;
  color: #fff;
  transition-duration: 0.2s;
  box-shadow: 0px 2px 3px #00000029;
}

.btn-secondary1:hover img {
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(50deg) brightness(101%) contrast(101%);
  transition-duration: 0.2s;
}

.flip-rotate {
  transition-duration: 0.5s;
}

.btn-secondary1:hover .flip-rotate {
  transform: rotate(-180deg);
  transition-duration: 0.5s;
}

.btn-info1:hover,
.btn-right-info1:hover,
.btn-info1:active,
.btn-info1:focus {
  background-color: #62bcc3 !important;
  box-shadow: 0px 2px 6px #62bcc3b0;
}

.bg-secondary {
  background-color: #ededed !important;
}

.text-info-dark {
  color: #009688;
}

.text-secondary1 {
  color: #999999;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

nav.navbar.navbar-expand-lg.navbar-dark.bg-dark.p-0 {
  min-height: 70px;
  font-size: 20px;
}

.home-link {
  margin-left: 135px;
}

.bg-dark {
  background-color: #000 !important;
}

.banner {
  height: 320px;
}

.logo {
  border-radius: 0px 0px 10px 10px;
}

.home-link a {
  padding: 10px 14px;
  border-radius: 7px;
  opacity: 0.5;
}

.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.home-link a:hover,
.home-active a {
  background: #333333;
  opacity: 1;
}

.home-link a:hover img,
.home-active img {
  filter: invert(30%) sepia(43%) saturate(391%) hue-rotate(136deg) brightness(87%) contrast(93%);
  opacity: 1;
  transition-duration: 0.2s;
}

.sidebar-header {
  padding: 8px 11px !important;
  border-radius: 10px 10px 0px 0px;
}

a.nav-link.dropdown-toggle:hover {
  color: #62bcc3;
  background-color: #333333;
  border-radius: 10px;
}

a.nav-link.dropdown-toggle {
  border-radius: 7px;
  padding: 8px 8px;
}

a.nav-link img {
  height: 22.5px;
  width: 22.5px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  border-radius: 10px;
}

.header-icon:hover {
  transition-duration: 0.2s;
  background: #333333;
  opacity: 1;
  border-radius: 7px;
  color: #62bcc3;
}

.header-icon svg {
  height: 23px;
  width: 26px;
}

.dropdown-item:hover {
  color: #ffffff;
  background-color: #62bcc3;
}

.banner {
  // background-image: url("assets/images//uploads/banner.png");
  background-position: center center;
  background-size: cover;
}

.sidebar {
  box-shadow: 0px 3px 6px #00000045;
  border-radius: 10px;
  position: relative;
  /*min-height: 628px;*/
  width: 320px;
  float: left;
  background: #11101d00;
  z-index: 100;
  transition: all 0.5s ease;
}

.sidebar ul {
  padding: 0px;
  margin: 0px;
}

.sidebar ul li a {
  list-style: none;
  padding: 11px 15px;
  font-size: 15px;
  display: block;
  text-decoration: none;
  color: inherit;

}


.sidebar ul li:hover,
.sidebar ul li:focus,
.sidebar-2 ul li:hover,
.sidebar-2 ul li:focus,
.active,
.active p,
.sidebar ul li:hover p {
  background-color: #62bcc3;
  color: #000;
}

.sidebar ul li:hover,
.sidebar ul li:focus,
.sidebar-2 ul li:hover,
.sidebar-2 ul li:focus,
.active,
.active p,
.sidebar ul li:hover span {
  background-color: #62bcc3;
  color: #fff;
}

.sidebar ul li:hover img,
.sidebar ul li.active a img,
.sidebar-2 ul li:hover img,
.sidebar-2 .active img {
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(50deg) brightness(101%) contrast(101%);
  transition-duration: 0.2s;
}

.right-section {
  position: relative;
  left: 12px;
  width: calc(100% - 325px);
  transition: all 0.5s ease;
  padding: 0px;
  /* border: 1px solid red; */
}

.i-icon {
  margin-top: -3px;
  cursor: pointer;
}

.hq-badge {
  padding: 5.5px 3.5px !important;
  border-radius: 20px;
  margin-left: -27px;
  margin-top: -3px;
  font-size: 11px;
  font-weight: normal;
  background-color: #62bcc3;
}

.bank-img {
  border-radius: 8px !important;
  overflow: hidden;
}

.acc-h {
  height: 95px;
}

.block-footer-h {
  height: 83px;
}

.group-img img {
  margin-left: -30px;
  border: 1px solid #f4f7f9;
  box-sizing: border-box;
  border-radius: 10px;
}

.shadow-block {
  box-shadow: 0px 3px 2px #00000045;
}

.shadow-block:hover {
  box-shadow: 0px 5px 15px #00000045;
  transform: translatey(-2px);
  transition-duration: 0.2s;
}

.border-box {
  border: 1px solid #fff;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 0px;
}

.shadow-block:hover .border-box {
  border-color: #9f9d9d;
}

.box-icons {
  margin-right: -3px;
}

.delete-icon,
.edit-icon {
  padding: 2px 5px 4px 5px;
  border-radius: 5px;
  margin-left: -3px;
}

.edit-icon {
  padding: 2px 0px 4px 0px;
}

// .delete-icon:hover,
// .edit-icon:hover {
//   background-color: #e5e5e5;
// }

.delete-icon:hover img {
  filter: invert(12%) sepia(90%) saturate(6562%) hue-rotate(15deg) brightness(89%) contrast(115%);
  transition-duration: 0.2s;
}

.edit-icon:hover img {
  filter: invert(37%) sepia(100%) saturate(2797%) hue-rotate(153deg) brightness(92%) contrast(101%);
  transition-duration: 0.2s;
}

/* Form input settings */
.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label,
.form-floating>.form-control:hover~label {
  opacity: 1;
  transform: scale(0.85) translateY(-0.7rem) translateX(0.1rem);
  color: #009688;
  font-weight: 500;
  top: 0;
}

.form-control:focus,
.form-control:hover {
  color: #000;
  outline: 0;
  box-shadow: unset;
}

.form-floating input,
.form-floating select {
  border-radius: 0px;
  border: 0px;
  border-bottom: 1px solid #999999;
  background-color: transparent;
}

.form-floating>label {
  top: 10px;
  padding: 1rem 0.75rem 1rem 0px;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
  padding: 1rem 0.75rem;
  padding-left: 3px;
  font-size: 15px;
}

.form-container {
  box-shadow: 0px 3px 6px #00000045;
  border-radius: 10px;
  height: 100%;
  min-height: 628px;
  background-color: #fff;
  overflow: hidden;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
  background-color: #c3c3c3;
  border-color: #c3c3c3;
  cursor: pointer;
}

.form-check-input:checked {
  background-color: #62bcc3;
  border-color: #62bcc3;
  cursor: pointer;
}

.form-check-input:focus {
  box-shadow: none;
}

.strategy {
  margin-top: 66px;
  width: 100%;
}

.strategy span {
  background-color: #ffffff;
  z-index: 2;
  padding-right: 10px;
  position: relative;
}

.strategy>div {
  height: 1px;
  position: relative;
  width: 100%;
  background-color: #6c757d;
  margin-top: -12px;
  z-index: 1;
}

.input-group-append {
  cursor: pointer;
}

span.input-group-append span {
  border: 0px;
  border-radius: 0px;
  border-bottom: 1px solid #999999;
  background-color: #fff !important;
}

.logo-container {
  height: 120px;
  width: 120px;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
}

.st-header {
  min-height: 135px;
  background-color: #62bcc3;
  padding: 20px;
}

.st-header img {
  margin-bottom: 15px;
}

.st-header p {
  line-height: 20px;
}

.st-content {
  padding: 20px;
  min-height: 170px;
}

.st-pill>div {
  padding: 10px 14px;
  border: 1px solid #00000045;
  border-radius: 40px;
}

.sidebar-2 {
  box-shadow: 0px 3px 6px #00000045;
  border-radius: 10px;
  position: relative;
  height: 100%;
  max-height: 628px;
  width: 250px;
  float: left;
  margin-right: 20px;
}

.sidebar-2 ul li>a {
  list-style: none;
  padding: 10px 15px 10px 20px;
  font-size: 15px;
  display: block;
  text-decoration: none;
  width: 100%;
  color: inherit;
}

.scroll-container {
  height: calc(100% - 112px);
  overflow-x: hidden !important;
  // overflow-y: scroll !important;
}

.aditional-user {
  height: 44px;
  width: 44px;
  border-radius: 9px;
  background-color: #c3c3c3;
  display: inline-block;
  padding: 9px;
  margin-left: -30px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
// .overflow-auto::-webkit-scrollbar,
// section.collapse-sidebar::-webkit-scrollbar,
// .maturity-stratergy::-webkit-scrollbar,
// .capability-heatmap::-webkit-scrollbar,
// .admin-main-container .accordion-container::-webkit-scrollbar,
// .after-filter-container::-webkit-scrollbar,
// textarea.form-control::-webkit-scrollbar,
// .right-container-uploads::-webkit-scrollbar,
// section.collapse-sidebar::-webkit-scrollbar,
// .profile-details::-webkit-scrollbar,
// .scroll-container::-webkit-scrollbar,
// .user-list-unit .row>div ul::-webkit-scrollbar {
//   display: none;
// }

/* Hide scrollbar for IE, Edge and Firefox */
.overflow-auto,
section.collapse-sidebar,
.maturity-stratergy,
.capability-heatmap,
.admin-main-container .accordion-container,
.after-filter-container,
textarea.form-control,
.right-container-uploads,
section.collapse-sidebar,
.profile-details,
.scroll-container,
.user-list-unit .row>div ul {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}

.delete-btn-sidebar-2 {
  width: 34px;
  height: 46.5px;
  background: #d90606;
  right: 0;
  justify-content: center;
  align-items: center;
  display: none;
  margin-top: -46.5px;
  float: right;
  z-index: 2;
  position: relative;
}

.sidebar-2 ul li:hover .delete-btn-sidebar-2,
.sidebar-2 ul li:focus .delete-btn-sidebar-2,
.sidebar-2 li.active .delete-btn-sidebar-2 {
  display: flex !important;
}

.user-list-unit .row div .heading {
  padding: 15px 20px;
  background-color: #e8f5f6;
}

.user-list-unit .row>div {
  border-right: 1px solid #c3c3c3;
  padding: 0px;
}

.user-list-unit .row div ul>li {
  padding: 15px 20px;
  border-bottom: 1px solid #c3c3c3;
}

.user-list-unit .row div:first-child ul>li a {
  padding: 15px 20px;
  text-decoration: none;
  display: block;
  color: inherit;
}

.user-list-unit .row div:first-child ul>li {
  padding: 0px;
}

.user-list-unit .row div:first-child ul li:hover {
  background-color: #62bcc3;
  color: #fff;
}

.user-list-unit .row div:first-child ul {
  height: 519px;
  overflow: scroll;
  margin-bottom: 0px;
}

.user-list-unit .row div:nth-child(2) ul {
  height: 465px;
  overflow: scroll;
  margin-bottom: 0px;
}

.user-list-unit .row div:nth-child(4) ul {
  height: 465px;
  overflow: scroll;
  margin-bottom: 0px;
}

.user-list-unit .row ul li label {
  cursor: pointer;
}

.st-content {
  padding: 20px;
  min-height: 170px;
  margin-bottom: -22px;
}

.st-pill>div {
  padding: 10px 14px;
  border: 1px solid #7070704b;
  border-radius: 40px;
  background-color: #fff;
  z-index: 2;
  position: relative;
  box-shadow: 0px 1px 3px #00000045;
}

/* switch */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 9px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: -5px;
  background-color: #62bcc3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider {
  background-color: #ccc;
}

.slider:before {
  background-color: darkgray;
}

input:checked+.slider:before {
  background-color: #62BCC3 !important;
}


input:checked+.slider {
  background-color: #b8e1e4;
}

input:focus+.slider {
  box-shadow: 0 0 1px #b8e1e4;
}

input:checked+.slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* switch end */

.st-footer {
  min-height: 116px;
  background-color: #ededed;
  margin-top: -22px;
  z-index: 1;
  padding: 11px;
  position: relative;
}

.shadow-block-data {
  border: 1px solid #d7d3d3;
}

// .shadow-block-data:hover {
//   border: 1px solid #9f9d9d;
//   box-shadow: 0px 5px 15px #00000045;
//   transform: translatey(-2px);
//   transition-duration: 0.2s;
// }

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c3c3c3;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c3c3c3;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c3c3c3;
}

.add-icon {
  height: 92px;
  width: 92px;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
  display: grid;
  align-content: space-evenly;
  justify-content: space-around;
  color: #999999;
}

.drag-drop {
  padding: 0px 16px 16px 16px;
}

.drag-drop div {
  border-radius: 10px;
  width: 100%;
  height: 380px;
  border: 1px dashed #707070;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #707070;
  text-align: center;
}

.search-icon {
  padding: 0px 3px 1px 4px;
  border-radius: 6px;
  cursor: pointer;
}

.search-icon:hover {
  background-color: #62bcc3;
}

.search-icon:hover img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(90deg) brightness(101%) contrast(104%);
  transition-duration: 0.2s;
}

/* slide 16 */
.action-header {
  height: 50px;
  background-color: #62bcc3;
  border-radius: 10px;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 8px 8px 8px 20px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.action-header .btn {
  background-color: #4d999f;
  padding: 4px 9px;
}

.action-header .btn:hover {
  background-color: #1e494d;
}

.action-header>div {
  min-width: 96px;
}

p.widget-heading:hover {
  color: #fff;
  background-color: #62bcc3;
  transition-duration: 0.2s;
  border-radius: 10px 10px 0px 0px;
}

button.btn.p-1.edit-white-icon img {
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(50deg) brightness(101%) contrast(101%);
  padding: 3px;
}

img.flip {
  padding-bottom: 2px;
}

.widget {
  min-height: 155px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
  overflow: hidden;
}

p.widget-heading {
  padding: 0px 20px;
  min-height: 50px;
  border-bottom: 1px solid #ededed;
  display: flex;
  align-items: center;
}

.profile-detail-container {
  box-shadow: 0px 3px 6px #00000045;
  border-radius: 10px;
  min-height: 270px;
  background-color: #fff;
  overflow: hidden;
}

.profile-image {
  border-radius: 10px;
  border: 1px solid #919191;
  height: 169px;
  width: 169px;
  overflow: hidden;
  overflow: hidden;
}

ul.list-pack li {
  line-height: 25px;
}

.td-user-img {
  max-width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 9px;
}

.profile-details {
  height: 571px;
  overflow: scroll;
}

td,
th {
  vertical-align: middle;
  border-color: #d7d7d7;
}

.profile-details th {
  height: 50px;
  border-color: #707070;
  background-color: #e8f5f6;
  box-shadow: 0px 1px 2px gray;
  padding-left: 15px;
  padding-right: 15px;
}

.profile-details td {
  height: 70px;
  padding-left: 15px;
  padding-right: 15px;
}

.thead-stickey {
  position: sticky;
  top: 0;
}

div.tbody-container {
  max-height: 400px !important;
  overflow: scroll;
}

.btn-secondary1:hover img.mngr {
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(50deg) brightness(339%) contrast(173%);
  transition-duration: 0.2s;
}

thead.thead-stickey {
  z-index: 5;
}

/* slide hover effect */
.st-footer::before {
  transform: scaleX(0);
  transform-origin: bottom right;
}

.st-footer:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
  transition: transform 0.5s ease;
}

.st-footer:hover p {
  color: #fff;
}

.st-footer::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  border-radius: 0px 0px 8px 0px;
  background: #62bcc3;
  z-index: -1;
  transition: transform 0.5s ease;
}

/* slide hover effect end*/

.personalisation-image {
  border-radius: 8px;
  height: 120px;
  height: 100%;
  width: 478px;
  overflow: hidden;
  // background-color: #1b1925;
}

.company-logo-image {
  border-radius: 10px;
  border: 1px solid #919191;
  height: 125px;
  width: 125px;
  overflow: hidden;
}

section.sub-header {
  min-height: 70px;
  background-color: #383838;
  box-shadow: 0px 3px 6px #00000029;
  padding: 10px 18px 10px 160px;
}

span.xirocco {
  background-color: #fff;
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 6px;
  padding: 6px;
  box-shadow: 0px 3px 4px #00000030;
  border: 1px solid #d6d2d2;
}

span.xirocco.text-center svg {
  height: 30px;
  width: 30px;
}

/* section.data-uploads {
  position: absolute;
  top: 140px;
  min-height: calc(100% - 140px);
  width: 100%;
} */
section.collapse-sidebar {
  position: absolute;
  top: 150px;
  height: calc(100% - 190px);
  width: 65px;
  border-radius: 0px 10px 10px 0px;
  background-color: #000;
  overflow-x: hidden;
  color: #fff;
  transition-duration: 0.2s;
  z-index: 115;
}

section.collapse-sidebar>li a {
  height: 45px;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 10px 20px !important;
  border-bottom: 1px solid #4d4d4d;
}

section.collapse-sidebar>li a i {
  right: 20px;
  position: absolute;
}

section.collapse-sidebar>li a:hover,
section.collapse-sidebar>li a:focus,
section.collapse-sidebar>li a:active {
  background-color: #62bcc3;
  color: #fff;
}

section.collapse-sidebar>li a:hover i,
section.collapse-sidebar>li a:focus i,
section.collapse-sidebar>li a:active i {
  transform: rotate(90deg);
  transition-duration: 0.7s;
}

.collapse-sidebar .collapse li a {
  background-color: #4a4a4a;
  border-bottom: 1px groove #626262;
}

.collapse-sidebar .collapse li a:hover,
.collapse-sidebar .collapse li a:active,
.collapse-sidebar .collapse li a:focus {
  background-color: #b0d6d9;
  color: #000000;
}

.collapse-sidebar .collapse li a:hover img,
.collapse-sidebar .collapse li a:active img,
.collapse-sidebar .collapse li a:focus img {
  filter: invert(0%) sepia(94%) saturate(17%) hue-rotate(148deg) brightness(-32%) contrast(132%);
  transition-duration: 0.2s;
}

section.collapse-sidebar li a span,
section.collapse-sidebar li a i {
  display: none !important;
}

section.collapse-sidebar:hover {
  width: 365px;
  transition-duration: 0.2s ease;
}

section.collapse-sidebar:hover li a span,
section.collapse-sidebar:hover li a i {
  display: block !important;
}

.xiroccocom {
  position: fixed;
  bottom: 0px;
  margin-bottom: 5px;
}

.right-container-uploads,
.right-container-filter {
  height: calc(100% - 180px);
  position: absolute;
  left: 83px;
  width: calc(100% - 101px);
  // overflow-y: scroll;
  padding-top: 12px;
}

.right-container-filter {
  overflow-y: visible !important;
  width: calc(100% - 92px);
}

.right-container-filter .sidebar-2 {
  max-height: 100%;
}

.right-container-filter .sidebar-2 li {
  display: flex;
  justify-content: space-between;
}

.right-container-filter .delete-btn-sidebar-2 {
  margin-top: 0px;
  height: auto;
  padding: 10px;
}

.bg-secondary1 {
  background-color: #e0e0e0;
}

.right-container-uploads .row {
  max-width: 940px;
  margin: auto;
}

.modal-body .row {
  max-width: 100% !important;
}

.data-blocks {
  box-shadow: 0px 3px 6px #00000045;
}

.data-blocks-header {
  padding: 9px 15px;
  min-height: 108px;
}

.data-blocks-header p {
  line-height: 18px;
}

.data-blocks li a {
  padding: 9px 20px;
  border-bottom: 1px solid #707070;
  background-color: #fff;
  color: #000;
  min-height: 50px;
  display: block;
  text-decoration: none;
}

.data-blocks li a:hover {
  background-color: #62bcc3;
  color: #fff !important;
}

.data-blocks li a:hover img {
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(50deg) brightness(101%) contrast(101%);
  transition-duration: 0.2s;
}

.data-blocks li:last-child a {
  border: 0px;
}

.after-filter-container1 {
  position: absolute;
  width: calc(100% - 261px);
  height: calc(100% - 12px);
  right: 0;
  top: 50px;
  // overflow-y: scroll;
  padding: 0px 5px 6px 5px;
}

.after-filter-container {
  position: absolute;
  width: calc(100% - 261px);
  height: calc(100% - 12px);
  right: 0;
  top: 10px;
  // overflow-y: scroll;
  padding: 0px 5px 6px 5px;
}

.save-button-fixed {
  position: absolute;
  right: 0px;
  top: 0px;
  padding-bottom: 25px;
  padding-top: 10px;
  width: calc(100% - 261px);
  padding: 7px 10px 6px 5px;
}

.accordion-button:not(.collapsed) {
  background-color: #62bcc3;
  box-shadow: none;
}

.accordion-button:not(.collapsed)::after,
.accordion-button::after {
  font-size: 8px;
  height: 6px;
  width: 6px;
  background-size: contain;
  background-color: #fff;
  border-radius: 20px;
  padding: 5px;
  border: 5px solid white;
}

.accordion-item:last-of-type {
  border-radius: 10px !important;
  box-shadow: 0px 3px 6px #00000045;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 10px !important;
}

.accordion-item:first-of-type .accordion-button {
  border-radius: 10px 10px 0px 0px;
}

textarea.form-control {
  border: 0px;
  border-bottom: 1px solid;
  border-radius: 0px;
}

.acordian-header-btn {
  float: right;
  position: absolute;
  right: 60px;
  margin-top: -45px;
  z-index: 5;
}

.add-benifits {
  padding: 13px 15px;
  background-color: #efefef;
}

.finance-table tr td:last-child {
  background-color: #e4e9e9;
}

.finance-table tr,
.finance-table td,
.finance-table tr,
.finance-table th {
  border-color: #707070;
  min-height: 113px;
  padding: 15px;
  width: 150px;
}

.finance-table hr {
  margin: 2px;
  border-color: #707070;
  opacity: 1;
}

.success-green {
  color: #3db500;
}

.subheader-title-red img {
  border: 1px solid #ff0001;
  border-radius: 50%;
  height: fit-content;
  background-color: #ff0001;
}

.subheader-title-red:hover p {
  color: #d90606 !important;
}

.subheader-title-red:hover img {
  border: 1px solid #ff6767;
  box-shadow: 0px 2px 5px #ff6767;
}

.subheader-title img {
  border-radius: 50%;
  height: fit-content;
  border: 1px solid #3db500;
  background-color: #3db500;
}

.subheader-title:hover p {
  color: #3db500 !important;
}

.subheader-title:hover img {
  border: 1px solid #54d711;

  box-shadow: 0px 2px 5px #54d711b8;
}

.bg-primary-dark {
  background-color: #19769a !important;
  color: #fff;
}

.bg-success-dark {
  background-color: #009688 !important;
  color: #fff;
}

.bg-success-black {
  background-color: #1e494d !important;
  color: #fff;
}

.bg-primary-light {
  background-color: #d6e7ed !important;
  color: #19769a;
}

.bg-success-light {
  background-color: #e0f2f1 !important;
  color: #009688;
}

.tags {
  background-color: #e0f2f3;
  padding: 7px 10px;
  border-radius: 30px;
  margin-bottom: 5px !important;
  display: inline-block;
  max-width: 100%;
  margin-right: 10px;
}

.color-box {
  background-color: #003a96;
  height: 30px;
  width: 30px;
  margin-right: 5px;
}

.text-preview-shape {
  background-color: #003a96;
  padding: 7px 10px;
  color: #fff;
}

.arrow-right {
  width: 0;
  height: 0;
  right: 0;
  float: right;
  margin-top: -7px;
  margin-right: -12px;
  border-top: 19px solid white;
  border-bottom: 19px solid white;
  border-left: 30px solid #003a96;
}

.add-uni-img {
  height: 100px;
  width: 100px;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
}

.w-70px {
  max-width: 70px;
}

.modal-header {
  min-height: 50px;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.ql-toolbar.ql-snow {
  border: 0px;
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
  border: 0px;
  border-bottom: 1px solid #000;
  min-height: 53px;
}

.breadcrumbs {
  color: #a6a6a6;
  margin-left: 15px;
  font-size: 18px;
  margin-bottom: -1px;
}

.breadcrumbs a {
  text-decoration: none;
  color: inherit;
}

.breadcrumbs a:hover {
  color: #fff;
}

/* .breadcrumbs span {
  color: #fff;
} */

.overlay-text {
  font-size: 13px;
  height: 70px;
  width: 70px;
  background-color: #00000061;
  border-radius: 10px;
  display: flex;
  align-items: center;
  color: #fff;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 8px;
  z-index: 1;
  position: relative;
  margin-top: -70px;
  cursor: pointer;
}

.uniquie-id .text-danger1 {
  padding: 5px !important;
}

.editor-icons-group img {
  height: 14px;
  padding: 0px 7px;
}

.editor-icons-group {
  border-right: 2px solid #dededd;
  padding-right: 5px;
  margin-right: 5px;
  padding-bottom: 5px;
}

.editor-icons-group select {
  word-wrap: normal;
  min-width: 100px;
  border: 0px;
}

.editor-dec textarea.form-control {
  padding-left: 0px;
}

.add-editor-image {
  height: 100px;
  width: 100px;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-content>.active {
  display: block;
  background-color: #fff;
  color: #000;
}

.tab-container {
  background-color: #fff;
  height: 100%;
  box-shadow: 0px 3px 6px #00000045;
  border-radius: 10px;
  overflow-y: auto;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
  background-color: #19769a;
  color: #fff;
  border-radius: 0px;
  border: 0px;
}

.nav-tabs .nav-link:nth-child(1):hover,
.nav-tabs .nav-link.active:nth-child(1) {
  background-color: green;
}

.nav-tabs .nav-link:nth-child(3):hover,
.nav-tabs .nav-link.active:nth-child(3) {
  background-color: #ef6c2b;
}

.nav-tabs .nav-link:nth-child(4):hover,
.nav-tabs .nav-link.active:nth-child(4) {
  background-color: #d42728;
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
  border-radius: 0px;
  color: #999999;
  border-left: 1px solid #cecece;
  font-weight: 600;
  background-color: #ededed;
}

.nav-tabs>.nav-link.active img,
.nav-tabs>.nav-link:hover img {
  filter: invert(0%) sepia(0%) saturate(2%) hue-rotate(50deg) brightness(218%) contrast(152%);
}

.total-str {
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #c7c7c7;
}

.row.editor-dec>div.d-none>img {
  max-width: 305px;
  float: right;
}

@media screen and (min-width: 1601px) {
  .row-cols-xxl-four>div {
    width: 25%;
  }

  .row-cols-xxl-six>div {
    width: 16.66%;
  }

  .row-cols-xxl-five>div {
    width: 20%;
  }
}

@media screen and (min-width: 1200px) {
  .table-action-buttons {
    min-width: 303px;
  }
}

@media screen and (max-width: 1400px) {
  // .sidebar.bg-white.overflow-hidden.collapse-content span {

  // }

  .sidebar.bg-white.overflow-hidden.collapse-content {
    max-width: 55px;
  }

  .responsive-unit {
    width: calc(100% - 55px);
  }

  .table-action-buttons {
    min-width: auto;
  }

  .table-action-buttons {
    text-align: end;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
}

.p-colorpicker-preview {
  height: 100% !important;
  width: 100% !important;
}

// For confirmation dialog styles

.btn-dialog-info,
.btn-dialog-info:active,
.btn-dialog-info:focus {
  background-color: #62bcc3 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-dialog-info:hover {
  background-color: #42989e !important;
  color: #fff !important;
}

.btn-dialog-danger,
.btn-dialog-danger:active,
.btn-dialog-danger:focus {
  background-color: rgb(225, 32, 32) !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-dialog-danger:hover {
  background-color: rgb(161, 12, 12) !important;
  color: #fff !important;
}

.p-dialog .p-dialog-header {
  background-color: #62bcc3 !important;
  height: 60px !important;
  color: #fff !important;
  align-items: center;
}

.p-dialog .p-dialog-content {
  padding: 1.5rem !important;
}

.p-calendar {
  width: 100%;
}


.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: #fff !important;
}

.p-editor-toolbar.ql-toolbar.ql-snow.ng-star-inserted {
  border: 0px;
  padding-left: 0px;
}

.title .p-editor-content.ql-container.ql-snow .ql-editor {
  padding-left: 0px;
  padding-bottom: 5px;
  width: 1300px;
}

.title-2 .p-editor-content.ql-container.ql-snow .ql-editor {
  padding-left: 0px;
  padding-bottom: 5px;
  width: 1000px;
}

.title-1 .p-editor-content.ql-container.ql-snow .ql-editor {
  padding-left: 0px;
  padding-bottom: 5px;
  width: 670px;
}

.title-1.ql-toolbar.ql-snow.ql-container.ql-snow {
  min-height: 50px !important;
}

.editor-desc .p-editor-content.ql-container.ql-snow {
  font-size: 20px;
  border-radius: 0px;
  min-height: 20px;
}

.p-dropdown-panel {
  max-height: 300px !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.5rem 1rem !important;
}

.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
  float: right !important;
}

.count {
  width: 400px;
}

.cah-top {
  display: flex;
  justify-content: space-between;
  margin-left: 55px;
}

.progress-spinner {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 30%;
  left: 50%;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #4d4d4d;
  opacity: 0.5;
}

// header fontsize
.h-fs {
  font-size: 18px;
}

// Title fontsize
.t-fs {
  font-size: 25px;
}

// Button fontsize
.bt-fs {
  font-size: 18px;
}

.hmap {
  font-size: 12px;
}

.hh-fs {
  font-size: 13px;
}

//Header text fontsize
.title .p-editor-content.ql-container.ql-snow {
  min-height: 60px;
  border-radius: 0px;
  font-size: 25px;
}

// Description fontsize
.editor-desc .ql-editor,
.editor-desc span.ql-picker-label {
  padding-left: 0px;
  font-size: 18px;
}

::-webkit-scrollbar-track {
  // background-color: #d4d4d4; Removed for making shadow line disappear
  -webkit-border-radius: 10px;
  border-radius: 40px;
  border: 2px solid #FFF;
  margin-top: 0px !important;

}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 50px;
  background: gray !important;
  width: 20px !important;
  height: 20px !important;
  margin-top: 0px !important;

}

::-webkit-scrollbar {
  display: block;
  width: 7px;
  height: 7px;
}

.uppercase {
  text-transform: uppercase;
}

.ql-container {
  font-size: 18px !important;

}

.cursor {
  cursor: pointer;
}

@page {
  size: A4;
  margin: 10mm 10mm;
}

.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 0.5cm;
  margin: 1cm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  page-break-after: always;
  page-break-inside: avoid;
  page-break-before: always;
}

@media print {

  .page {
    padding: auto;
    padding-top: 0px !important;
    margin: auto;
    margin-top: 0px !important;
    border: initial;
    border-radius: initial;
    width: 100vw;
    min-height: 29.7cm;
    box-shadow: initial;
    background: transparent !important;
    page-break-after: always;
    page-break-inside: avoid;
    page-break-before: always;
  }

  // .no-print-area {
  //   display: none;
  // }

  .btn {
    display: none;
  }

}
.p-relative{
  position: relative;
}